body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(images/paper-bg.jpg);
  background-size: cover;
  background-position: top left;
  opacity: 1;
  filter: blur(0px);
  font-size: 20px;
  font-family: "Delius", serif;
  font-weight: 400;
  font-style: normal;
  color: #753614
}

.container {
  display: flex;
  min-height: 300px;
  height: 95vh;
  width: 100vw;
  overflow: hidden;
  padding: 1rem;
}

.header {
  display: block;
  width: 330px;
  height: 70px;
  margin: 0 auto 35px auto;
}

.header .text {
  display: inline-block;
  padding-top: 3px;
  vertical-align: middle;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 3px 3px 0 #f0d09dcf, -3px -3px 0 #f0d09dcf, 3px -3px 0 #f0d09dcf, -3px 3px 0 #f0d09dcf, 3px 3px 0 #f0d09dcf;
}

.header .logo {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  margin-right: 5px;
  background-image: url(images/logo.webp);
  background-size: cover;
  background-position: top left;
}

.box {
  margin: 0 auto;
  background: #eecda6cc;
  padding: 3rem;
  border-radius: 10px;
  min-width: 30vw;
  max-width: 60vw;
  overflow-x: hidden;
  overflow-y: auto;
  border: 2px solid #c87d40;
  box-shadow: 0 4px 12px #0000004a;
}

@media screen and (max-width: 600px) {
  .box {
    padding: 2rem;
  }
}
